import React from "react"
import { Wrapper, Title, Text } from "./style"

const HeroPresentation = () => {
  return (
    <Wrapper>
      <div>
        <Title data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
          Conference Presentations
        </Title>
        <Text data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">
          Enjoy some of the highlights from previous LymeMIND conferences
        </Text>
      </div>
    </Wrapper>
  )
}

export default HeroPresentation
