import React, { Fragment, useState } from "react"
import { useStaticQuery } from "gatsby"
import { useMediaQuery } from "react-responsive"
import {
  Wrapper,
  Title,
  Text,
  EventContainer,
  ButtonsContainer,
  ViewEventBtn,
  RegisterBtn,
  BottomText,
  BottomContainer,
  TitleCaret,
} from "./style"
import { PrimaryBtnExternal } from "../../../utils/GlobalComponents"
import Blob from "../../common/Blob/Blob"
import RegisterModal from "../../../components/RegisterModal/RegisterModal"
import CalendarIcon from "../../../images/lyme-local-images/Calendar-48x48.png"
import PinIcon from "../../../images/lyme-local-images/Pin-48x48.png"

const Hero = ({ eventInfo }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
  }

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  return (
    <Fragment>
      {eventInfo ? (
        <Wrapper>
          <Blob
            toTop={
              isTabletOrMobileDevice ? "15%" : isNotebookOrIpad ? "15%" : "0%"
            }
            toRight={
              isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "10%" : "10%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "0"
                : isNotebookOrIpad
                ? "15vw"
                : "37.5vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
            delay={"400"}
          />
          <Blob
            toTop={
              isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "0%" : "0%"
            }
            toLeft={
              isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "-5%" : "-5%"
            }
            customWidth={
              isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "12vw" : "12vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
            delay={"400"}
          />
          <Blob
            toTop={
              isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "30%" : "20%"
            }
            toLeft={
              isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "5%" : "5%"
            }
            customWidth={
              isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "0" : "6vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
            delay={"400"}
          />
          <div>
            <RegisterModal
              visible={modalOpen}
              onClose={() => handleModalOpen()}
            />

            <Fragment>
              <Title
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
                data-aos-offset="-200"
              >
                {eventInfo.event_title}
              </Title>
              <TitleCaret />
              <Text
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="400"
                data-aos-offset="-200"
                dangerouslySetInnerHTML={{
                  __html: eventInfo.event_description,
                }}
              ></Text>
              <EventContainer
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="600"
                data-aos-offset="-200"
              >
                <ul>
                  <li
                    data-aos="fade"
                    data-aos-duration="800"
                    data-aos-delay="400"
                  >
                    <img src={CalendarIcon} alt="Calendar" />

                    <span>{eventInfo.date}</span>
                  </li>
                  <li
                    data-aos="fade"
                    data-aos-duration="800"
                    data-aos-delay="400"
                    data-aos-offset="-200"
                  >
                    <img src={PinIcon} alt="Pin" />

                    <div className="event__place">
                      <span>{eventInfo.location}</span>
                      {eventInfo.is_registration_open && (
                        <em>Register early to secure your spot</em>
                      )}
                    </div>
                  </li>
                </ul>

                <ButtonsContainer>
                  {eventInfo.events_agenda && (
                    <ViewEventBtn
                      data-aos="fade"
                      data-aos-duration="800"
                      data-aos-delay="400"
                      data-aos-offset="-200"
                      href="/agenda"
                      secondary={"A"}
                      as="a"
                    >
                      <span>View Event Agenda</span>
                    </ViewEventBtn>
                  )}
                  {eventInfo.is_registration_open && (
                    // <RegisterBtn
                    //   to="/upcoming-events"
                    //   primary={"A"}
                    //   as="button"
                    //   onClick={() => setModalOpen(!modalOpen)}
                    //   data-aos="fade"
                    //   data-aos-duration="800"
                    //   data-aos-delay="400"
                    //   data-aos-offset="-200"
                    // >
                    //   <span>Register Now</span>
                    // </RegisterBtn>
                    <PrimaryBtnExternal
                      // onClick={() => handleModalOpen(true)}
                      href="https://lyme-mind-2022.open-exchange.net/welcome"
                      target="_blank" rel="noopener noreferrer"
                      primary={"A"}
                    >
                      <span>Register Now</span>
                    </PrimaryBtnExternal>
                  )}
                </ButtonsContainer>
              </EventContainer>
              <BottomContainer>
                <BottomText>
                  Cohen Lyme & Tickborne Disease Initiative
                </BottomText>
              </BottomContainer>
            </Fragment>
          </div>
        </Wrapper>
      ) : (
        <Wrapper></Wrapper>
      )}
    </Fragment>
  )
}

export default Hero
