import styled from "styled-components"

import { LG } from "../../../utils/constants"

export const Wrapper = styled.div`
  /*  background: #ffffff; */
  opacity: 0.9;
  border-radius: 2px;

  transition: ${props => props.theme.defaultTransition()};
  background-position: top, right, bottom, left;

  > div {
    display: flex;
    flex-direction: column-reverse;
  }

  .dzu-submitButtonContainer {
    position: absolute;
    bottom: 0;
    right: 40px;
  }

  .dzu-dropzone {
    width: 100%;
    height: 200px;
    background: white;
    border: 0;
    width: auto;
    text-align: center;
    line-height: 100px;
    overflow: hidden;
    /*     background: linear-gradient(
        to right,
        #f4904d47 50%,
        rgba(255, 255, 255, 0) 0%
      ),
      linear-gradient(#f4904d47 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(to right, #f4904d47 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(#f4904d47 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 10px 2px, 2px 10px; */
    transition: ${props => props.theme.defaultTransition()};
    margin: initial;
       box-shadow: 1px 4px 20px rgba(243, 139, 151, 0.13);
    background: #ffffff;
    padding: 0 0 1.6rem;
    color: #40444f;
    border: 2px dashed #f4904d6e;
    border-radius: 1em;
    cursor: pointer;

  }

    .dzu-inputLabelWithFiles {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      margin: 0;
      font-family: "Chakra Petch", sans-serif;
      width: 100%;

      .svg-container {
        top: -30px !important;
      }

      .custom-input-wrapper {
        width: 100%;
        max-width: 320px;
      }
    }

    .custom-input-wrapper {
      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: ${props => props.theme.navy};

        padding-top: 40px;
      }

      h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #b0c9cb;

        @media (max-width: ${LG}) {
          position: absolute;
          bottom: 20px;
          width: 100%;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      .svg-container {
        position: absolute;
        width: 58px;
        height: 58px;
        top: 25px;
        left: 50%;
        transform: translate(-50%, 0);
        background: transparent;
        border-radius: 50%;
      }

      svg {
        width: 32px;
        height: 32px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -12px;
        margin-top: -12px;
      }
    }

    &:hover {
      .dzu-dropzone {
        border: 0;
        width: auto;
        text-align: center;
        line-height: 100px;
        border: 2px dashed #f4904d;
        transition: ${props => props.theme.defaultTransition()};
      }

      svg {
        path {
          fill: #f4904d;
        }
      }
    }

    .dzu-dropzoneActive {
      background: none !important;
      background-color: #f4904d;

      box-shadow: 4px 20px 60px rgba(243, 139, 151, 0.5);
      border: 3px solid #f4904d;
      .custom-input-wrapper {
        .svg-container {
          background: linear-gradient(97.08deg, #f38b97 20.01%, #f4904d 75.82%);

          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  
`

export const PreviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.neutralSilver};
  margin-top: 20px;

  > div {
    padding: 0;
    &:first-child {
      display: flex;
      justify-content: space-between;
    }
  }
`
export const OkIcon = styled.img`
  width: 16px !important;
  height: 16px;
`

export const Trash = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;

  transition:${props => props.theme.defaultTransition()};

  &:hover{
    opacity:0.7;
  }
`

export const Download = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
`

export const FileTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.neutralDark};
`

export const FileSizeEl = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  opacity: 0.4;
  color: ${props => props.theme.neutralDark};
`

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;

  > span {
    padding-left: 10px;
  }
`
export const IconsRight = styled.div`
  display: flex;
  > div {
    margin-right: 10px;
  }
  button {
    &:first-child {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 5px;
    }
  }
`

export const PercentageNumber = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #b0c9cb;
`

export const ProgressBar = styled.div`
  width: ${props => props.progress + "%"};
  transition: ${props => props.theme.defaultTransition()};
  background-color: #f4904d;
  height: 1px;
  margin-top: 12px;
`
