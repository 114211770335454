import React, { Fragment } from "react"
import { TopTitle, Wrapper } from "./style"

const EventsPhotoTitle = ({ UpcomingPastEventPhotos }) => {
  return (
    <Wrapper>
      <TopTitle data-aos="fade-up" data-aos-duration="1000">
        {UpcomingPastEventPhotos ? (
          <Fragment>{UpcomingPastEventPhotos.section_title}</Fragment>
        ) : (
          <div></div>
        )}
      </TopTitle>
    </Wrapper>
  )
}

export default EventsPhotoTitle
