import React, { Fragment, useState } from "react"
import {
  Wrapper,
  Grid,
  Title,
  BigText,
  SmallText,
  MainButton,
  BgImage,
} from "./style"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { useMediaQuery } from "react-responsive"
import RegisterModal from "../../../components/AbstractModal/AbstractModal"

const Abstract = ({ info }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

 

  return (
    <Wrapper>
      {info.show_abstract_submissions_section !== "no" ? (
        <Fragment>
          <RegisterModal
            visible={modalOpen}
            onClose={() => handleModalOpen()}
          />
          <div>
            
            <Fragment>
              <DefaultWrapper>
                <Grid>
                  <div>
                    <Title maxWidth="471px">{info.section_title}</Title>
                    {isDesktopOrLaptop && (
                      <MainButton
                        to=""
                        primary={"A"}
                        as="button"
                        onClick={() => handleModalOpen(true)}
                      >
                        <span>Submit an Abstract</span>
                      </MainButton>
                    )}
                  </div>
                  <div>
                    <BigText
                      dangerouslySetInnerHTML={{
                        __html: info.section_description,
                      }}
                    />
                    <SmallText
                      dangerouslySetInnerHTML={{
                        __html: info.submission_instructions,
                      }}
                    />
                    {isTabletOrMobileDevice && (
                      <MainButton
                        to=""
                        primary={"A"}
                        as="button"
                        onClick={() => handleModalOpen(true)}
                      >
                        <span>Submit an Abstract</span>
                      </MainButton>
                    )}
                  </div>
                </Grid>
              </DefaultWrapper>

              <BgImage
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="400"
                bg={
                  "https://cms.lymemind.org/wp-content/uploads/2020/07/conference-2-scaled.jpg"
                }
              />
            </Fragment>
          </div>
        </Fragment>
      ) : (
        <div></div>
      )}
    </Wrapper>
  )
}

export default Abstract
