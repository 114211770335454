import React, { useEffect, useState, Fragment } from "react"
import Rodal from "rodal"
import {
  Wrapper,
  FormImput,
  InputWrap,
  Caret,
  Message,
  Grid,
  Title,
  Text,
  Submit,
  SubmitContainer,
  SuccessTitle,
  SuccessContainer,
  SuccessText,
  Contact,
  ValidationMark,
  RequiredGeneralMessage
} from "./style"
import "rodal/lib/rodal.css"
import axios from "axios"

import DragAndDrop from "../../components/common/DragAndDrop/DragAndDrop"
import RegisterCheck from "../../images/green-checked.svg"
import MailIcon from "../../images/mail.svg"

const AbstractModal = ({ visible, onClose }) => {
  const [msgActive, setMsgActive] = useState(0)
  const [successScreen, setSuccessScreen] = useState(false)
  const [showWarning, SetShowWarning] = useState(false)
  const [positionScroll, setPositionScroll] = useState(null)
  const [validateEmail, setValidateEmail] = useState("empty")

  const [state, setState] = useState({
    fullname: "",
    email: "",
    phonenumber: "",
    inputFiles: [],
  })

  const handleValidMail = email => {
    let re = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)

    if (state === "") {
      setValidateEmail(false)
    }

    if (re.test(email)) {
      setValidateEmail(true)
    } else {
      setValidateEmail(false)
    }
  }

  useEffect(() => {
    if (visible) {
      setPositionScroll(window.pageYOffset)

      setTimeout(() => {
        document.documentElement.style.overflowY = "scroll"
        document.body.style.top = `-${positionScroll}px`
        document.body.style.left = `0px`
        document.body.style.right = `0px`
        document.body.style.bottom = `0px`
      }, 0)

      setTimeout(() => {
        document.body.style.position = `fixed`
      }, 400)
    } else {
      setTimeout(() => {
        document.body.style.position = ""
        document.documentElement.style.overflowY = "auto"
        window.scrollTo(0, positionScroll)
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, positionScroll])

  useEffect(() => {
    setValidateEmail("empty")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    setState(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })

    if (name === "email") {
      handleValidMail(state.email)
    }
  }

  const handleInputFiles = inputFile => {
    setState(prevState => {
      return {
        ...prevState,
        inputFiles: state.inputFiles.concat(inputFile),
      }
    })
  }

  const removeInputFiles = inputFile => {
    state.inputFiles.forEach((item, x) => {
      if (item.name === inputFile) {
        setState(prevState => {
          return {
            ...prevState,
            inputFiles: [...state.inputFiles].filter((_, i) => i !== x),
          }
        })
      }
    })
  }

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "auto"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const submitForm = async () => {
    try {
      var bodyFormData = new FormData()
      bodyFormData.set("fullname", state.fullname)
      bodyFormData.set("email", state.email)
      bodyFormData.set("phonenumber", state.phonenumber)
      state.inputFiles.forEach(f => {
        bodyFormData.append("formfiles[]", f)
      })
      await axios({
        method: "post",
        url:
          "https://cms.lymemind.org/wp-json/contact-form-7/v1/contact-forms/680/feedback",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      setSuccessScreen(true)
    } catch (error) {}
  }

  const [formData] = useState({
    PersonalInfo: [
      {
        fullName: "",
        placeholder: "Full Name",
        activeRequired: true,
        help: false,
        message: "",
        name: "fullname",
        type: "fullname",
      },
      {
        Email: "",
        placeholder: "E-mail",
        activeRequired: true,
        help: false,
        message: "",
        name: "email",
        type: "email",
      },
      {
        Adress: "",
        placeholder: "Phone Number",
        activeRequired: true,
        help: false,
        message: "",
        name: "phonenumber",
        type: "phonenumber",
      },
    ],
  })

  return (
    <Wrapper>
      <Rodal
        enterAnimation={"slideUp"}
        leaveAnimation={"fade"}
        duration={300}
        visible={visible}
        onClose={onClose}
      >
        {successScreen === false ? (
          <Grid>
            <div>
              <Title>
                Submit an abstract for the 2020 LymeMIND Poster Session
              </Title>
              <Text>
                Send a brief abstract (no longer than 250 words) and your
                presentation title by Friday, September 4th, 2020.
              </Text>
              <Text>
                Please note that poster presenters are required to be registered
                for the conference in order to be eligible for the review
                process. Not all submissions will be accepted due to space
                limitations.
              </Text>
            </div>
            <div>
              <Fragment>
                {formData["PersonalInfo"].map((item, i) => {
                  return (
                    <InputWrap key={i}>
                      {i === 1 ? (
                        <div>
                          <FormImput
                            placeholder={item.placeholder}
                            required={item.activeRequired}
                            name={item.name}
                            value={state.name}
                            onChange={e => handleInputChange(e)}
                            errorInputMail={
                              item.name === "email" && validateEmail
                            }
                            autoComplete="off"
                          />

                          {state.email.length > 0 ? (
                            <ValidationMark
                              borderColor={validateEmail}
                            ></ValidationMark>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          <FormImput
                            placeholder={item.placeholder}
                            required={item.activeRequired}
                            name={item.name}
                            value={state.name}
                            onChange={e => handleInputChange(e)}
                            autoComplete="off"
                            type={i === 2 ? "number" : "text"}
                          />

                          {state[item.type].length > 0 ? (
                            <ValidationMark
                              borderColor={state[item.type].length > 1}
                            ></ValidationMark>
                          ) : null}
                        </div>
                      )}

                      {item.activeRequired && (
                        <Message index={i + 1} msgVisible={msgActive}>
                          <span>Required Field</span>
                        </Message>
                      )}

                      {item.help && (
                        <Message
                          index={i + 1}
                          msgVisible={msgActive}
                          helpCaret={item.help}
                        >
                          <span>{item.message}</span>
                        </Message>
                      )}

                      <Caret
                        activeRequired={item.activeRequired}
                        onMouseOver={() => setMsgActive(i + 1)}
                        onFocus={() => setMsgActive(i + 1)}
                        onMouseLeave={() => setMsgActive(0)}
                        helpCaret={item.help}
                      ></Caret>
                    </InputWrap>
                  )
                })}

                <DragAndDrop
                  handleInputFiles={handleInputFiles}
                  removeInputFiles={removeInputFiles}
                />
                {showWarning ?  <RequiredGeneralMessage>
                    Check required fields marked
                  </RequiredGeneralMessage>: null}
                <SubmitContainer>
                  <Submit
                    to="/"
                    primary={"A"}
                    as="button"
                    onClick={() => {
                      if (
                        state.fullname !== "" &&
                        state.email !== "" &&
                        state.phonenumber !== "" &&
                        state.inputFiles !== ""
                      ) {
                        submitForm()
                      } else {
                        SetShowWarning(true)
                      }
                    }}
                  >
                    <span>Submit</span>
                  </Submit>
                </SubmitContainer>
              </Fragment>
            </div>
          </Grid>
        ) : (
          <SuccessContainer>
            <img src={RegisterCheck} alt={"checked"} />
            <SuccessTitle maxWidth={"unset"}>
              Thank you for your submission
            </SuccessTitle>
            <SuccessText>
              The LymeMIND team will review all submissions and notify selected
              presenters by [DATE].
            </SuccessText>
            <SuccessText>
              You may be contacted sooner if we have any questions regarding
              your submission
            </SuccessText>
            <Contact>
              <span>For questions, please contact:</span>
              <div>
                <img src={MailIcon} alt="Mail" />
                <a href="mailto:lymemind@mssm.edu">lymemind@mssm.edu </a>
              </div>
            </Contact>
          </SuccessContainer>
        )}
      </Rodal>
    </Wrapper>
  )
}

export default AbstractModal
