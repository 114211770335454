import styled from "styled-components"
import { LG, BREAKPOINT_CEL_ONLY } from "../../../utils/constants"

import {
  MainH2,
  MainParagraph,
  PrimaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  img {
    width: 100%;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 90px 50px 120px;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    padding: 60px 30px 90px;
  }
`

export const Title = styled(MainH2)`
  padding-bottom: 90px;
  font-size: 40px;
  line-height: 48px;

  @media (max-width: ${LG}) {
    line-height: 36px;
    padding-bottom: 20px;
    max-width: unset;
  }
`

export const BigText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;

  color: ${props => props.theme.neutralDark};
  max-width: 456px;
  strong {
    font-weight: 500;
  }

  @media (max-width: ${LG}) {
    font-size: 22px;
    max-width: unset;
  }
`

export const SmallText = styled(MainParagraph)`
  color: ${props => props.theme.neutralDark};
  max-width: 456px;
  font-weight: 500;

  @media (max-width: ${LG}) {
    font-weight: normal;
    max-width: unset;
  }
`

export const MainButton = styled(PrimaryBtn)`
  @media (min-width: ${LG}) {
    max-width: 200px;
  }
`

export const BgImage = styled.div`
  width: 100%;
  height: 440px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  margin-top:50px;

  @media(max-width:${BREAKPOINT_CEL_ONLY}){
    
    height:330px;
  }
`
