import styled from "styled-components"
import { LG } from "../../utils/constants"

export const Wrapper = styled.div`
  margin: 140px 0;

  @media (max-width: ${LG}) {
    margin: 90px 0;
  }
`
