import styled from "styled-components"
import { LG, BREAKPOINT_CEL_ONLY } from "../../../utils/constants"

import {
  MainH2,
  PrimaryBtn,
  SecondaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  padding: 120px 0 0 0;
  position: relative;
  overflow: hidden;

  @media (max-width: ${LG}) {
    padding: 120px 30px 40px 30px;

    > div {
      text-align: center;
      ul {
        display: grid;
        align-items: center;
        background-color: ${props => props.theme.neutralSilver};
        padding: 30px 50px 30px;
        margin-top: 15px;
        margin-bottom: 20px;
        border-radius: 8px;

        @media (max-width: ${LG}) {
          background-color: white;
          padding: 0 0 15px;
        }
      }
    }
  }

  ul {
    display: grid;
    grid-gap: 15px 0;

    @media (max-width: ${LG}) {
      padding-left: 50px;
    }

    li,
    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .event__place {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }
    }
    li {
      margin-right: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: ${props => props.theme.neutralDark};

      @media (max-width: ${LG}) {
        margin-bottom: 0;

        &:first-child {
          margin-bottom: 20px;
        }
      }

      em {
        font-size: 16px;
        line-height: 24px;
        color: #b0c9cb;
        font-style: normal;

        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        @media (min-width: 768px) {
          margin-left: 8px;
        }
      }
    }

    @media (max-width: ${LG}) {
      flex-direction: column;
    }
  }
`

export const Title = styled(MainH2)`
  margin: 0 auto 32px;
  max-width: 616px;
  display: block;
  text-align: center;

  
  @media (max-width: ${LG}) {
    line-height: 36px;
    margin: 0 auto 25px;
    text-align:left;
    max-width: unset;
   /*  &:after {
      content: "";
      width: 38px;
      height: 4px;
      display: block;
      background: ${props => props.theme.orangeGradient};
      margin-top: 15px;
    } */
  }

  @media(max-width:${BREAKPOINT_CEL_ONLY}){
    margin:0 0 20px;
    max-width:300px;
  }
`

export const TitleCaret = styled.div`
  @media (max-width: ${LG}) {
    width: 38px;
    height: 4px;
    display: block;
    background: ${props => props.theme.orangeGradient};
    margin-top: 5px;
    margin-bottom: 15px;
  }
`

export const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.navy};

  max-width: 620px;
  margin: 0 auto;

  @media (max-width: ${LG}) {
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    max-width: unset;
    letter-spacing: 0.01em;
    margin: 0;
  }
`

export const EventContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 45px 50px;
  margin: 0 auto;
  margin-top: 60px;
  border-radius: 8px;
  box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.08);
  border-radius: 8px;
  background-color: white;

  max-width: 983px;
  position: relative;
  z-index: 2;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    margin-top: 20px;
    padding: 0;
    box-shadow: none;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${LG}) {
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
`

export const ViewEventBtn = styled(SecondaryBtn)`
  margin-right: 20px;

  @media (max-width: ${LG}) {
    margin-bottom: 10px;
  }
`

export const RegisterBtn = styled(PrimaryBtn)``

export const BottomContainer = styled.div`
  height: 253px;
  background-color: ${props => props.theme.neutralSilver};
  display: flex;
  align-items: flex-end;
  margin-top: -80px;

  @media (max-width: ${LG}) {
    background-color: white;

    h6 {
      text-align: left;

      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }
`

export const BottomText = styled.h6`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  margin: 0 auto;
  padding-bottom: 80px;

  color: ${props => props.theme.navy};
`
