import styled from "styled-components"
import { MainH2, MainH3 } from "../../../utils/GlobalComponents"
import { LG } from "../../../utils/constants"

export const Wrapper = styled.div`
  > div {
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: ${LG}) {
    padding:0 30px;
  }
`

export const Title = styled(MainH2)`
  max-width: unset;
  font-size: 40px;
  padding-bottom: 20px;
`

export const Text = styled(MainH3)`
  font-size: 24px;
  max-width: 480px;
  color: ${props => props.theme.navy};
  line-height: 31px;
  margin: 0 auto;
  font-weight: 400;

  @media (max-width: ${LG}) {
    max-width:unset;
  }
 
`
