import styled from "styled-components"

import { LG, BREAKPOINT_CEL_ONLY } from "../../../utils/constants"

export const Wrapper = styled.div`
  overflow: hidden;
  padding-bottom: 50px;
  margin-top: 80px;

  .slick-slide {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    > div {
      margin: 0 2.5px 0 2.5px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;

      @media (max-width: ${LG}) {
      }
    }

    &.slick-center {
      opacity: 1;
    }
  }

  .slick-dots {
    bottom: -35px;
    li {
      background-color: red;
      border-radius: 50%;
      width: 4px;
      height: 4px;
      vertical-align: middle;
      transition: ${props => props.theme.defaultTransition()};
      background-color: ${props => props.theme.neutralGreen};

      &.slick-active {
        width: 12px;
        height: 12px;
        background: linear-gradient(97.08deg, #f38b97 20.01%, #f4904d 75.82%);
      }

      button {
        width: 12px;
        height: 12px;
        &:before {
          display: none;
        }
      }
    }
  }

  .slick-next {
    top: 0;
    right: unset;
    left: 84%;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translate(0);
    z-index: 10;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    top: 0;
    right: 80%;
    left: 0;
    bottom: 0;
    width: 16%;
    height: 100%;
    transform: translate(0);
    z-index: 10;

    &:before {
      display: none;
    }
  }
`

export const ImageSlider = styled.div`
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: 400px;

  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    height: 330px;
  }
`
