import React, { Fragment } from "react"
import { useStaticQuery } from "gatsby"
import moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/UpcomingEvent/Hero/Hero"
import EventsPhotos from "../components/Home/EventPhotos/EventPhotos"
import Abstract from "../components/UpcomingEvent/Abstract/Abstract"
import ConferencePresentations from "../components/ConferencePresentations/ConferencePresentations"
import PastEvents from "../components/PastEvents/PastEvents"
import Artist from "../components/Artist/Artist"

const UpcomingEvent = () => {
  const {
    allWordpressWpUpcomingEvents: { edges },
  } = useStaticQuery(graphql`
    query UpcomingEvent {
      allWordpressWpUpcomingEvents {
        edges {
          node {
            title
            date
            type
            acf {
              conference_presentations_section {
                presentations {
                  post_title
                }
              }
              artists {
                artist_name
                artist_website
                work_description
                work_title
                work_image {
                  source_url
                }
              }
              event_information {
                date
                time
                event_description
                event_title
                events_agenda
                is_registration_open
                location
                show_event_in_menu
              }
              previous_event_section {
                photos
                section_title
              }
              abstract_submission_section {
                show_abstract_submissions_section
                section_title
                section_description
                submission_instructions
              }
              past_events_section {
                past_events {
                  post_title
                }
              }
            }
          }
        }
      }
    }
  `)

  const eventYears = []

  const datesArray = []
  const fullDayes = []
  if (edges) {
    edges.forEach(date => {
      datesArray.push()
      fullDayes.push(date.node)
    })
  }

  const sortedDates = edges
    .slice()
    .sort(
      (a, b) =>
        parseInt(moment(b.node.date).format("x")) +
        parseInt(moment(a.node.date).format("x"))
    )
  const lastEdge = sortedDates[0]
  if (lastEdge) {
    lastEdge.node.acf.conference_presentations_section.presentation !== undefined &&
    lastEdge.node.acf.conference_presentations_section.presentation !== null &&
      lastEdge.node.acf.conference_presentations_section.presentation.forEach(
        title => {
          eventYears.push(title.post_title)
        }
      )
  }

  const sortedYears = eventYears.slice().sort((a, b) => b - a) // Make a copy with .slice()

  return (
    <Layout>
      {edges && (
        <Fragment>
          <SEO
            title={lastEdge.node.acf.event_information.event_title}
            description={lastEdge.node.acf.event_information.event_description}
          />

          <Hero eventInfo={lastEdge.node.acf.event_information} />

          {lastEdge.node.acf.artists !== null && (
            <Artist
              node={lastEdge.node}
              eventArtist={lastEdge.node.acf.artists}
            />
          )}

          {lastEdge.node.acf.previous_event_section.section_title !== null && (
            <EventsPhotos
              UpcomingPastEventPhotos={lastEdge.node.acf.previous_event_section}
            />
          )}

          <Abstract info={lastEdge.node.acf.abstract_submission_section} />
          {lastEdge.node.acf.conference_presentations_section.presentations !==
            null && (
            <ConferencePresentations
              upcomingEvent={true}
              eventYears={sortedYears}
            />
          )}

          <PastEvents
            pastEventSections={
              lastEdge.node.acf.past_events_section.past_events
            }
          />
        </Fragment>
      )}
    </Layout>
  )
}

export default UpcomingEvent
