import React, { Fragment } from "react"
import "react-dropzone-uploader/dist/styles.css"
import Dropzone from "react-dropzone-uploader"
import { useMediaQuery } from "react-responsive"

import {
  Wrapper,
  PreviewWrap,
  OkIcon,
  Trash,
  FileTitle,
  NameContainer,
  FileSizeEl,
  IconsRight,
  ProgressBar,
  PercentageNumber,
} from "./style"
import OkIconPic from "../../../images/ready-drop.svg"
import TrashPic from "../../../images/trash.svg"
import DocumentPic from "../../../images/document.svg"
import StopLoad from "../../../images/remove-load.svg"
import SVGIcon from "../../common/SVGIcon"

const Preview = ({ meta, removeInputFiles, files }) => {
  const removeFile = id => {
    files.forEach((item, y) => {
      if (item.meta.id === id) {
        item.remove()
      }
    })
  }

  const bytesToSize = bytes => {
    const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb"]
    if (bytes === 0) return "n/a"
    const i = parseInt(
      Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)),
      10
    )
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  }

  const { name, percent, size, id } = meta

  return (
    <Fragment>
      <PreviewWrap id={id}>
        <div>
          <NameContainer>
            {percent === 100 ? (
              <OkIcon src={OkIconPic} alt="ok" />
            ) : (
                <OkIcon src={DocumentPic} alt="document" />
              )}
            <FileTitle>{name}</FileTitle>
            <FileSizeEl>{bytesToSize(size)}</FileSizeEl>
          </NameContainer>
          {percent === 100 ? (
            <IconsRight>
              <Trash
                onClick={() => {
                  removeInputFiles(name)
                  removeFile(id)
                }}
              >
                <img src={TrashPic} alt="trash" />
              </Trash>
            </IconsRight>
          ) : (
              <IconsRight>
                <PercentageNumber>{Math.round(percent)}%</PercentageNumber>
                <Trash
                  onClick={() => {
                    removeInputFiles(name)
                    removeFile(id)
                  }}
                >
                  <img src={StopLoad} alt="stop load" />
                </Trash>
              </IconsRight>
            )}
        </div>
        <ProgressBar progress={percent} />
      </PreviewWrap>
    </Fragment>
  )
}

const Layout = ({
  input,
  previews,
  dropzoneProps,
  files,
  extra: { maxFiles },
}) => {
  return (
    <div>
      {previews}
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
    </div>
  )
}

const DragAndDrop = ({ handleInputFiles, removeInputFiles }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append("fileField", file)
    handleInputFiles(file)
    return { url: "https://httpbin.org/post", body }
  }

  const handleSubmit = (files, allFiles) => {

    allFiles.forEach(f => f.remove())
  }

  return (
    <Wrapper>
      <Dropzone
        getUploadParams={getUploadParams}
        LayoutComponent={Layout}
        onSubmit={handleSubmit}
        PreviewComponent={props => (
          <Preview {...props} removeInputFiles={removeInputFiles} />
        )}
        inputWithFilesContent={
          <div key={1} className="custom-input-wrapper">
            <div className="svg-container">
              <SVGIcon name="dragUpload" width={16} />
            </div>

            <h4>Add more files</h4>
            <h5>Accepted file types: .Txt, .Doc, or PDF</h5>
          </div>
        }
        inputContent={
          <div key={1} className="custom-input-wrapper">
            <div className="svg-container">
              <SVGIcon name="dragUpload" width={16} />
            </div>
            {isDesktop && <h4>Drag or click to upload file</h4>}
            {isTabletOrMobileDevice && <h4>Click to upload file</h4>}

            <h5>Accepted file types: .Txt, .Doc, or PDF</h5>
          </div>
        }
      />
    </Wrapper>
  )
}

export default DragAndDrop
