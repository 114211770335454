import React, { useState, Fragment } from "react"
import { useStaticQuery } from "gatsby"
import Slider from "react-slick"
import { navigate } from "gatsby"

import { Wrapper, Card, Mask, Title } from "./style"

const PastEvents = ({ pastEventSections }) => {
  const {
    allWordpressWpPastEvents: { edges },
  } = useStaticQuery(graphql`
    query PastEventsQuery {
      allWordpressWpPastEvents(filter: {wordpress_id: {ne: 1627}}) {
        edges {
          node {
            title
            acf {
              past_event_image {
                source_url
              }
              past_event_title_category
              past_event_subtitle
              past_event_link
            }
          }
        }
      }
    }
  `)

  const finalArray = []

  edges.forEach(singleNode => {
    pastEventSections.forEach(pastEvent => {
      if (pastEvent.post_title === singleNode.node.title) {
        finalArray.push(singleNode)
      }
    })
  })

  const [clientX, setClientX] = useState(0)

  const handleMouseDown = e => {
    setClientX(e.clientX)
  }

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "220px",
    slidesToShow: 2,
    autoplay: true,
    speed: 800,
    dots: false,
    navigation: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: "40px",
          dots: true,
        },
      },
    ],
  }

  return (
    <Fragment>
      {pastEventSections ? (
        <Wrapper>
          <Title
            data-aos="fade-up"
            data-aos-once={true}
            data-aos-duration="800"
            data-aos-delay="400"
          >
            Past Events
          </Title>
          <div
            data-aos-once={true}
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="400"
          >
            <Slider
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="300"
              data-aos-once={true}
              {...settings}
            >
              {finalArray.map(node => (
                <div key={node}>
                  {/* eslint-disable-next-line */}

                  <div
                    onMouseDown={handleMouseDown}
                    onClick={e => {
                      const variation = e.clientX - clientX
                      if (variation > -10 && variation < 10) {
                        navigate("/past-event-" + node.node.title)
                      }
                    }}
                  >
                    <Card
                      bg={
                        node.node.acf.past_event_image
                          ? node.node.acf.past_event_image.source_url
                          : ""
                      }
                    >
                      <Mask />
                      <div>
                        <h3>{node.node.title}</h3>
                        <h4>Conference Highlights</h4>
                      </div>
                    </Card>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Fragment>
  )
}

export default PastEvents
