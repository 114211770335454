import styled from "styled-components"

import { MainH2, MainParagraph, PrimaryBtn } from "../../utils/GlobalComponents"

import { focusAnimation } from "../../utils/KeyFrames"

import {
  InputContainer,
  RegularInput,
  InputMessage,
  InputCaret,
  HelpImg,
} from "../../utils/GlobalComponents"

import { LG, XL } from "../../utils/constants"

export const Wrapper = styled.div`
  h6 {
    font-size: 14px;
    line-height: 24px;
    padding-left: 5px;
    font-weight: 500;
    color: ${props => props.theme.navy};
    padding-top: 20px;
  }

  .rodal {
    overflow-y: scroll;
    background-color: #faf7f575;
    padding: 0;
  }

  .rodal-dialog {
    background-color: ${props => props.theme.neutralSilver};
    max-height: none;
    max-width: 1184px;
    padding: 0 0 30px;
    box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.08);
    margin: 30px auto;
    border-radius: 8px;
    z-index: 200;
    padding: 0;
    /*    max-height: 820px !important;
    height: calc(100% - 140px) !important; */

    @media (max-width: ${XL}) {
      max-width: 850px;
    }

    @media (max-width: ${LG}) {
      height: 100% !important;
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
  }

  .rodal-mask {
    opacity: 0.8;
    background: transparent;
    outline: 0;
  }

  .rodal-dialog::-webkit-scrollbar {
    width: 8px;
  }
  .rodal-dialog {
    scrollbar-width: thin;
    scrollbar-color: linear-gradient(0deg, #f38b97 20.01%, #f4904d 100%);
  }
  .rodal-dialog::-webkit-scrollbar-thumb {
    background: rgba(176, 201, 203, 0.22);
    border-radius: 20px;
  }

  .rodal-close {
    background-color: white;
    opacity: 1;
    border-radius: 50%;
    box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
    padding: 27px;
    transition: ${props => props.theme.defaultTransition()};
    top: -20px;
    right: -20px;

    &:after,
    &:before {
      width: 40%;

      background-color: ${props => props.theme.lightGreen};
      margin: 0 auto;
      opacity: 1;
      left: 30%;
    }
    @media (max-width: ${LG}) {
      top: 15px;
      right: 15px;
    }
  }
`

export const Title = styled(MainH2)`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;

  margin-bottom: 20px;

  @media (max-width: ${XL}) {
    max-width: unset;
  }

  @media (max-width: ${LG}) {
    line-height: 36px;
    padding-right: 15px;
  }
`

export const Text = styled(MainParagraph)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.01em;
`
export const SubmitContainer = styled.div`
  text-align: right;
  margin-top: 50px;
`

export const Submit = styled(PrimaryBtn)`
  width: 240px;
  display: inline-block;
`

export const InputWrap = styled(InputContainer)``

export const Caret = styled(InputCaret)``

export const FormImput = styled(RegularInput)`
  margin-bottom: 28px;

  &:focus {
    background: linear-gradient(175.34deg, #f38b97 20.01%, #f4904d 75.82%);
    background-position: bottom 0 left 0, bottom 5px left 0;
    background-repeat: no-repeat;
    color: #003541;
    animation: ${focusAnimation} 0.6s ease-in 0s forwards;
  }
`

export const ValidationMark = styled.div`
  width: 100%;
  position: absolute;
  top: unset;
  right: 0;
  left: 0;
  bottom: ${props => (props.bottomFix ? props.bottomFix : "28px")};

  background-color: ${props => (props.borderColor ? "#09DCAE" : "#FF5454")};
  height: 2px;
  margin: 0 auto;
  border-bottom-left-radius: 170px;
  border-bottom-right-radius: 170px;
`

export const Message = styled(InputMessage)``

export const ImageHelp = styled(HelpImg)``

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  height: 100%;
  border-radius: 8px;
  > div {
    &:first-child {
      background-color: white;
      padding: 88px;
    }
    &:last-child {
      padding: 88px;
      background-color: ${props => props.theme.neutralSilver};
    }
  }

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr;

    > div {
      &:last-child {
        padding: 40px 88px 88px;
        background-color: ${props => props.theme.neutralSilver};
      }
      &:first-child {
        background-color: transparent;
        padding: 88px 88px 0;
      }
    }
  }

  @media (max-width: ${LG}) {
    > div {
      &:last-child {
        padding: 40px 30px 88px;
      }
      &:first-child {
        background-color: transparent;
        padding: 88px 30px 0;
      }
    }
  }
`

export const SuccessContainer = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > img {
    width: 59px;
    height: 59px;
  }

  @media (max-width: ${LG}) {
    text-align: left;
    padding: 0 20px;
    display: block;

    > img {
      margin-top: 80px;
    }
  }
`
export const SuccessTitle = styled(MainH2)`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  margin: 20px 0 40px;
`

export const SuccessText = styled(MainParagraph)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.01em;
  background-color: ${props => props.theme.neutrallDark};

  padding-bottom: 30px;

  @media (min-width: ${LG}) {
    max-width: 440px;
    margin: 0 auto;
  }

  @media (max-width: ${LG}) {
    text-align: left;
    max-width: unset;
  }
`

export const Contact = styled.div`
  display: flex;
  align-items: center;
  margin-top: 200px;

  @media (max-width: ${LG}) {
    display: block;
    width: 100%;
    margin-top: 50px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.neutralDark};
    letter-spacing: 0.01em;
    margin-right: 30px;
  }
  a {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.navy};
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-decoration: none;
    display: inline;
  }
  > div {
    display: flex;
    align-items: center;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 15px;
  }
`

export const RequiredGeneralMessage = styled.div`
  margin-top: 20px;
  margin-bottom: -20px;
  font-size: 14px;
  line-height: 24px;
  padding-left: 5px;
  font-weight: 500;
  color: #ff5454;
`
