import React from "react"
import { Wrapper } from "./style"

import SliderPresentations from "../ConferencePresentations/SliderPresentations/SliderPresentations"
import Hero from "../ConferencePresentations/Hero/Hero"
import { useStaticQuery } from "gatsby"

const ConferencePresentations = ({ upcomingEvent, eventYears }) => {
 

  return (
    <Wrapper>
      <Hero />
      <SliderPresentations upcomingEvent={upcomingEvent}  eventYears={eventYears} />
    </Wrapper>
  )
}

export default ConferencePresentations
