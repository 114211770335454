import React, { Fragment } from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { Wrapper, ImageSlider } from "./style"
import Slider from "react-slick"

import EventsPhotoTitle from "./EventsPhotoTitle/EventsPhotoTitle"

const EventsPhotos = ({ UpcomingPastEventPhotos }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "220px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    navigation: true,
    initialSlide: 2,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: "40px",
        },
      },
    ],
  }

  return (
    <Fragment>
      {UpcomingPastEventPhotos && (
        <Wrapper>
          <DefaultWrapper>
            <EventsPhotoTitle
              UpcomingPastEventPhotos={UpcomingPastEventPhotos}
            />
          </DefaultWrapper>

          <div data-aos="fade-up" data-aos-duration="1000">
            {UpcomingPastEventPhotos ? (
              <Slider {...settings}>
                {(UpcomingPastEventPhotos.photos || []).map(node => (
                  <div key={node}>
                    <ImageSlider bg={node} />
                  </div>
                ))}
              </Slider>
            ) : (
              <div></div>
            )}
          </div>
        </Wrapper>
      )}
    </Fragment>
  )
}

export default EventsPhotos
