import styled from "styled-components"
import { LG, XL } from "../../utils/constants"
import { MainH2 } from "../../utils/GlobalComponents"

export const Wrapper = styled.div`
  margin-top: 40px;
  overflow: hidden;

  @media (max-width: ${LG}) {
    margin-bottom: 20px;
  }

  .slick-slide {
    opacity: 0.6;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-slider {
    padding: 45px 0 50px;

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: ${LG}) {
      padding: 0 0 0;
    }
  }
  .slick-slide {
    cursor: pointer;

    > div {
      margin: 0 15px 0 15px;
      position: relative;

      @media (max-width: ${LG}) {
        margin: 0 3px 0 3px;
      }
    }

    @media (max-width: ${LG}) {
      margin: 0;
      /*   margin: 0 2.5px 0 2.5px; */
    }
  }

  .slick-track,
  .slick-list {
    height: 350px !important;
    padding: 50px 0;
    position: relative;
    top: -20px;
  }

  .slick-next {
    top: 0;
    right: unset;
    left: 88%;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translate(0);
    z-index: 10;

    &:before {
      display: none;
    }

    @media (max-width: ${XL}) {
      left: 82%;
      height: 50%;
      transform: translate(0, 39%);
    }
  }
  .slick-prev {
    top: 0;
    right: 80%;
    left: 0;
    bottom: 0;
    width: 12%;
    height: 100%;
    transform: translate(0);
    z-index: 10;

    @media (max-width: ${XL}) {
      width: 18%;
      height: 50%;
      transform: translate(0, 39%);
    }

    &:before {
      display: none;
    }

  iframe {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @media (max-width: ${LG}) {
    .slick-dots {
      bottom: 20px;
      li {
        background-color: red;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        vertical-align: middle;
        transition: ${props => props.theme.defaultTransition()};
        background-color: #b0c9cb;

        &.slick-active {
          width: 12px;
          height: 12px;
          background: linear-gradient(97.08deg, #f38b97 20.01%, #f4904d 75.82%);
        }

        button {
          width: 12px;
          height: 12px;
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .rodal-dialog {
    width: 100% !important;
    height: 100% !important;
    max-width: 1000px;
    max-height: 550px;
    background-color: transparent;
    box-shadow: none;
  }

  .rodal-close {
    top: 10px;
    right: -30px;
    width: 32px;
    height: 32px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    &:after,
    &:before {
      border-radius: 0;
      outline: 0 !important;
      background-color: ${props => props.theme.neutralSilver};
      opacity: 0.8;
    }

    &:focus {
      outline: 0;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const Card = styled.div`
  border-radius: 8px;
  background-color: white;
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: 272px;
  position: relative;
  display: flex;
  box-shadow: 10px 6px 48px rgba(243, 139, 151, 0.3);

  transition: ${props => props.theme.defaultTransition()};

  > div {
    &:last-child {
      position: absolute;
      bottom: 30px;
      left: 25px;
      transition: ${props => props.theme.defaultTransition("all", 0.3)};
    }
  }

  h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 30px;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;

    color: #ffffff;
  }

  &:hover {
    > div {
      &:last-child {
        bottom: 40px;
      }
    }
  }
`
export const Mask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 28.94%,
    #f38b97 100%
  );
  border-radius: 8px;
`

export const Title = styled(MainH2)`
  font-size: 40px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 25px;
`
